import {
  VarButton,
  VarCategory,
  VarNumber,
  VarSelect,
  VarUI,
} from "react-var-ui";
import { CogIcon } from "@heroicons/react/solid";
import { useState } from "react";
import schema from "./schema";

function ConfigPanel({
  config,
  setConfig,
  isChanged,
  onUpdateVisualizationClick,
}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <CogIcon
        className="h-12 w-12 absolute top-4 right-4 cursor-pointer"
        onClick={() => setOpen(!open)}
      />
      <div
        className={`z-40 ${
          open ? "block" : "hidden"
        } w-[100vw] h-[100vh] absolute top-0 left-0 bg-gray-900 opacity-50`}
        onClick={() => setOpen(false)}
      ></div>
      <div
        className={
          "absolute z-50 top-16 right-0 sm:right-16 shadow-2xl rounded-md overflow-hidden max-w-full" +
          (open ? "" : " hidden")
        }
      >
        <VarUI updateValues={setConfig} values={config}>
          <VarCategory label="Display">
            <VarSelect
              label="Chart Type"
              path="chartType"
              options={[
                {
                  key: "treemap",
                  label: "treemap",
                },
                {
                  key: "sunburst",
                  label: "sunburst",
                },
                {
                  key: "bubble",
                  label: "bubble",
                },
              ]}
            />
            {["treemap", "sunburst"].includes(config.chartType) && (
              <VarNumber
                label="Max Display Depth"
                min={2}
                max={6}
                step={1}
                integer
                showInput
                showButtons
                path="maxDisplayDepth"
              />
            )}
          </VarCategory>
          <VarCategory label="Data">
            {["treemap", "sunburst"].includes(config.chartType) && (
              <VarSelect
                label="Scaling Variable"
                path="scalingVariable"
                options={schema}
              />
            )}
            {["bubble"].includes(config.chartType) && (
              <>
                <VarSelect
                  label="Granularity"
                  path="displayDepth"
                  options={[
                    {
                      key: 0,
                      label: "Sector",
                    },
                    {
                      key: 1,
                      label: "3-digit NAICS",
                    },
                    {
                      key: 2,
                      label: "4-digit NAICS",
                    },
                    {
                      key: 3,
                      label: "5-digit NAICS",
                    },
                    {
                      key: 4,
                      label: "6-digit NAICS",
                    },
                  ]}
                />
                <VarSelect label="X Axis" path="xVar" options={schema} />
                <VarSelect label="Y Axis" path="yVar" options={schema} />
                <VarSelect
                  label="Bubble Size"
                  path="sizeVar"
                  options={[{ label: "--", key: null }, ...schema]}
                />
              </>
            )}
          </VarCategory>
          <VarButton
            buttonLabel="Update Visualization"
            className={isChanged && "green-button-container"}
            onClick={() => {
              setOpen(false);
              onUpdateVisualizationClick();
            }}
          />
        </VarUI>
      </div>
    </>
  );
}

export default ConfigPanel;
