import { useEffect, useState } from "react";
// import NAICS_TREE from "./data/us_state_naics_detailedsizes_2017.json";
import NAICS_TREE from "./data/EC1700SIZECONCEN.json";
import Plot from "react-plotly.js";
import ConfigPanel from "./ConfigPanel";
import { Waveform } from "@uiball/loaders";
import schema from "./schema";

// Get labels and parents via a DFS of NAICS_TREE
function extractTreeData(roots, labelFn, valueKey) {
  let labels = roots.map((r) => labelFn(r));
  let parents = roots.map(() => "All Establishments");
  let values = roots.map((r) => r[valueKey]);
  let stack = [...roots];
  while (stack.length) {
    const current = stack.pop();
    if (current.children) {
      current.children.forEach((child) => {
        stack.push(child);
        labels.push(labelFn(child));
        values.push(child[valueKey]);
        parents.push(labelFn(current));
      });
    }
  }
  return { labels, parents, values };
}

function getChildrenAtDepth(nodes, depth) {
  if (depth === 0) {
    return nodes;
  }
  return nodes.map((n) => getChildrenAtDepth(n.children, depth - 1)).flat();
}

function extractBubbleData(roots, depth, xVar, yVar, sizeVar) {
  const nodes = getChildrenAtDepth(roots, depth);
  let x = nodes.map((n) => n[xVar]);
  let y = nodes.map((n) => n[yVar]);
  let text = nodes.map((n) => n["NAICSDSCR"] + " (" + n["NAICS"] + ")");
  let size = nodes.map((n) => (sizeVar ? n[sizeVar] : 100));
  let sizeref = sizeVar
    ? (2.0 * Math.max(...size.map((s) => parseInt(s)))) / 100 ** 2
    : 1;
  return { x, y, text, size, sizeref };
}

function refreshPlotlyData(config) {
  if (config.chartType === "treemap" || config.chartType === "sunburst") {
    const { labels, parents, values } = extractTreeData(
      NAICS_TREE,
      (c) => c["NAICSDSCR"] + " (" + c["NAICS"] + ")",
      config.scalingVariable
    );

    return {
      labels,
      parents,
      values,
      branchvalues: "total",
      textinfo: "label+value+percent parent",
      hoverinfo: "label+value+percent parent",
      type: config.chartType,
      insidetextfont: {
        color: "rgb(248,250,252)",
      },
      maxdepth: config.maxDisplayDepth,
    };
  } else if (config.chartType === "bubble") {
    const { x, y, text, size, sizeref } = extractBubbleData(
      NAICS_TREE,
      config.displayDepth,
      config.xVar,
      config.yVar,
      config.sizeVar
    );
    console.log(sizeref);
    return {
      x,
      y,
      text,
      mode: "markers",
      marker: {
        size,
        sizeref,
        sizemode: "area",
      },
    };
  }
}

function refreshPlotlyLayout(config) {
  return {
    autosize: true,
    paper_bgcolor: "rgb(31,41,55)",
    plot_bgcolor: "rgb(31,41,55)",
    margin: {
      t: 20,
    },
    xaxis: {
      gridcolor: "rgba(248,250,252,0.3)",
      color: "rgba(248,250,252,0.8)",
      title: {
        text: schema.find((e) => e.key === config.xVar).label,
      },
    },
    yaxis: {
      gridcolor: "rgba(248,250,252,0.3)",
      color: "rgba(248,250,252,0.8)",
      title: {
        text: schema.find((e) => e.key === config.yVar).label,
      },
    },
  };
}

function App() {
  const [config, _setConfig] = useState({
    maxDisplayDepth: 3,
    chartType: "treemap",
    scalingVariable: schema[0].key,
    displayDepth: 1,
    xVar: schema[0].key,
    yVar: schema[1].key,
    sizeVar: null,
  });
  const [data, setData] = useState({});
  const [layout, setLayout] = useState({});
  const [loading, setLoading] = useState(true);
  const [needsRefresh, setNeedsRefresh] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    setData(refreshPlotlyData(config));
    setLayout(refreshPlotlyLayout(config));
    setLoading(false);
  }, []);

  useEffect(() => {
    if (needsRefresh) {
      setData(refreshPlotlyData(config));
      setLayout(refreshPlotlyLayout(config));
      setNeedsRefresh(false);
      setLoading(false);
    }
  }, [needsRefresh, config]);

  function refreshVisualization() {
    setLoading(true);
    setIsChanged(false);
    setNeedsRefresh(true);
  }

  function setConfig(c) {
    setIsChanged(true);
    _setConfig(c);
  }

  return (
    <div className="flex flex-col h-full bg-gray-800 text-slate-50 font-sans">
      <div className="text-center font-bold text-5xl mt-12">
        NAICS Map
        <ConfigPanel
          config={config}
          setConfig={setConfig}
          isChanged={isChanged}
          onUpdateVisualizationClick={refreshVisualization}
        />
      </div>
      <div className="text-center font-thin mb-6">
        Data Source:{" "}
        <a
          className="underline"
          href="https://www.census.gov/data/datasets/2017/econ/susb/2017-susb.html"
        >
          2017 SUSB Annual Dataset
        </a>
      </div>
      {loading ? (
        <div className="flex mt-24 justify-center">
          <Waveform color="#F8FAFC" />
        </div>
      ) : (
        <div className="flex-grow">
          <Plot
            data={[data]}
            layout={layout}
            config={{ responsive: true }}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      )}
    </div>
  );
}

export default App;
