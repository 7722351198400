export default [
  {
    key: "ESTB",
    label: "# Establishments",
  },
  {
    key: "RCPT",
    label: "Revenue ($1,000)",
  },
  {
    key: "EMPL",
    label: "Employment",
  },
  {
    key: "FIRM",
    label: "# Firms",
  },
  {
    key: "PAYR",
    label: "Annual Payroll ($1,000)",
  },
  {
    key: "50_LG_MKT_SHARE",
    label: "Market Share of Top 50 Firms",
  },
];
